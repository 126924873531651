import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BriefcaseIcon,
  CurrencyDollarIcon,
  DocumentIcon,
  CheckBadgeIcon
} from "@heroicons/react/20/solid";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getJob } from "../../features/job/jobSlice";
import api from "../../utils/api";
import Navbar from "../Home/Navbar";
import Loading from "../Loading";
import moment from "moment";

const ViewJob = () => {
  const [params] = useSearchParams();
  const jobIdParams = params.get("job");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { jobApply, authModal } = useSelector((state) => state.ui);
  const { isLoading: loading, job } = useSelector((state) => state.job);

  const [textView, setTextView] = useState(""); // estado para controlar qual texto deve ser exibido

  //for loading animation
  const [isLoading, setisLoading] = useState(false);

  //saved jobs jobid
  const [savedJobs, setSavedjobs] = useState([]);

  //get all saved jobs
  const getSavedJobs = async () => {
    const API_URL = `candidate/getsaveJob/${user.userId}`;
    const response = await api.get(API_URL);
    let mapped = response.data.find.map((ele) => ele.JobID);
    setSavedjobs(mapped);
  };
  //get resume details
  const getResume = async () => {
    const API_URL = `candidate/viewResume/${user.userId}`;
    const response = await api.get(API_URL);
    console.log(response);
    if (!response.data.find) {
      return null;
    } else {
      return response.data.find._id;
    }
  };

  useEffect(() => {
    // Verificar a lógica de data aqui e definir textView corretamente
    const jobCreatedAt = moment(
      jobIdParams ? job?.createdAt : jobApply.viewData.job.createdAt
    ).utc();
    const jobDeadline = moment(
      jobIdParams ? job?.jobDeadline : jobApply.viewData.job.jobDeadline
    ).utc();

    const hoje = moment(); // data atual

    // Verificar se a data atual é antes da data de fechamento da vaga
    if (hoje.isBefore(jobDeadline)) {
      setTextView("Vaga publicada");
    } else {
      setTextView("Vaga Encerrada");
    }
  }, [jobIdParams, job, jobApply]);

  const applyJob = async () => {
    if (user === null) {
      toast.info("Você só pode aplicar a uma vaga logado na plataforma", {
        theme: "dark",
      });
      return;
    }
    setisLoading(true);
    const ResumeID = await getResume();

    if (!ResumeID) {
      setisLoading(false);
      toast.info("resume no setup", { theme: "dark" });
      return;
    }
    const userID = user.userId; //logged user
    const JobID = jobIdParams ? job?._id : jobApply.viewData.job._id;
    const CompanyID = jobIdParams
      ? job?.company?._id
      : jobApply.viewData.job.company._id;

    try {
      const API_URL = `candidate/apply`;
      const response = await api.post(API_URL, {
        userID: userID,
        JobID: JobID,
        ResumeID: ResumeID,
        CompanyID: CompanyID,
      });
      setisLoading(false);
      toast.info(response.data.msg, { theme: "dark" });
    } catch (error) {
      console.log(error);
      setisLoading(false);
      toast.info("error occured when applying", { theme: "dark" });
    }
  };
  //save method
  const saveJob = async () => {
    if (
      savedJobs.includes(jobIdParams ? job?._id : jobApply.viewData.job._id)
    ) {
      //delete job
      try {
        const API_URL = `candidate/delsaveJob/${
          jobIdParams ? job?._id : jobApply.viewData.job._id
        }`;
        const response = await api.delete(API_URL);
        toast.info(response.data.msg, { theme: "dark" });
      } catch (error) {
        console.log("err", error);
        toast.error("error", { theme: "dark" });
      }
    } else {
      //save job
      try {
        const API_URL = `candidate/saveJob`;
        const response = await api.post(API_URL, {
          userID: user.userId,
          JobID: jobIdParams ? job?._id : jobApply.viewData.job._id,
        });
        toast.info(response.data.msg, { theme: "dark" });
      } catch (error) {
        toast.error("error", { theme: "dark" });
      }
    }
    getSavedJobs();
  };
  useEffect(() => {
    if (jobIdParams) {
      dispatch(getJob(jobIdParams));
    }

    if (user !== null) {
      getSavedJobs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className="w-screen h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className={`${authModal && "h-screen overflow-hidden"}`}>
        <Navbar />
        <header className="relative h-72 bg-[#14163A] flex  items-center">
          <br />
          <div className="pt-10 pl-10 pr-10 w-full ">
            <div className="box-content h-48 w-full ">
              <img
                src={
                  jobIdParams
                    ? job?.company?.photoUrl
                    : jobApply.viewData.job.company.photoUrl
                }
                alt="job-img"
                className=" w-36 h-36 float-left object-cover bg-white rounded-lg shadow-2xl mt-6"
              />
              
              <div className="relative pl-4 pr-4 pt-4 h-full ">
                {/* {textView === "Vaga publicada" && (
                  <h1 className="text-white absolute top-8">
                    {textView}{" "}
                    {moment(
                      jobIdParams
                        ? job?.createdAt
                        : jobApply.viewData.job.createdAt
                    )
                      .utc()
                      .format("DD/MM/YYYY")}
                  </h1>
                )}
                {textView === "Vaga Encerrada" && (
                  <h1 className="text-white absolute pr-12 top-8 left-28">
                    {textView}{" "}
                    {moment(
                      jobIdParams
                        ? job?.jobDeadline
                        : jobApply.viewData.job.jobDeadline
                    )
                      .utc()
                      .format("DD/MM/YYYY")}
                  </h1>
                )} */}
                <button
                  onClick={applyJob}
                  className="bg-indigo-500 hover:bg-indigo-500  text-white absolute float-right right-4 bottom-2  px-5 py-2 rounded-full "
                >
                  Quero me candidatar
                </button>

                {savedJobs.includes(
                  jobIdParams ? job?._id : jobApply.viewData.job._id
                ) && (
                  <button onClick={saveJob}>
                    <h1 className="text-white absolute right-60 bottom-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </h1>
                  </button>
                )}

                {!savedJobs.includes(
                  jobIdParams ? job?._id : jobApply.viewData.job._id
                ) && (
                  <button onClick={saveJob}>
                    <h1 className="text-white absolute right-60 bottom-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z"
                        />
                      </svg>
                    </h1>
                  </button>
                )}

                {user === null && (
                  <button
                    title="login to save"
                    disabled={true}
                    className="cursor-not-allowed"
                  >
                    <h1 className="text-white absolute right-60 bottom-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 3l1.664 1.664M21 21l-1.5-1.5m-5.485-1.242L12 17.25 4.5 21V8.742m.164-4.078a2.15 2.15 0 011.743-1.342 48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185V19.5M4.664 4.664L19.5 19.5"
                        />
                      </svg>
                    </h1>
                  </button>
                )}
              </div>
            </div>
          </div>
        </header>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
              <div className="absolute inset-0 -z-10 overflow-hidden">
                <svg
                  className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                      width={200}
                      height={200}
                      x="50%"
                      y={-1}
                      patternUnits="userSpaceOnUse"
                    >
                      <path d="M100 200V.5M.5 .5H200" fill="none" />
                    </pattern>
                  </defs>
                  <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                    <path
                      d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                      strokeWidth={0}
                    />
                  </svg>
                  <rect
                    width="100%"
                    height="100%"
                    strokeWidth={0}
                    fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
                  />
                </svg>
              </div>
              <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                  <div className="lg:pr-4">
                    <div className="lg:max-w-lg">
                      
                      <p className="text-3xl font-semibold leading-7 text-indigo-600">
                        {jobIdParams
                          ? job?.jobTitle
                          : jobApply.viewData.job.jobTitle}
                      </p>
                      <h1 className="mt-2 text-3xl font-semibold tracking-tight text-indigo-600 sm:text-2xl">
                        {jobIdParams
                          ? job?.company?.name
                          : jobApply.viewData.job.company.name}
                      </h1>
                      <p className="mt-2 text-xl leading-8 text-indigo-500">
                        Localização:{" "}
                        {jobIdParams
                          ? job?.cidade
                          : jobApply.viewData.job.cidade}/{jobIdParams
                          ? job?.estado
                          : jobApply.viewData.job.estado}
                      </p>
                      <p className="mt-2 text-xl leading-8 text-indigo-500">
                        Nível de experiência:{" "}
                        {jobIdParams
                          ? job?.jobExperience
                          : jobApply.viewData.job.jobExperience}
                      </p>
                      
                      
                      <p className="mt-2 text-xl leading-8 text-indigo-500">
                        Categoria:{" "}
                        {jobIdParams
                          ? job?.jobCategory
                          : jobApply.viewData.job.jobCategory}
                      </p>
                    </div>
                  </div>
                </div>
                
                <div>
                  {textView === "Vaga publicada" && (
                    <h1 className="text-indigo-600 font-bold absolute right-8 top-6">
                      {textView}{" "}
                      {moment(
                        jobIdParams
                          ? job?.createdAt
                          : jobApply.viewData.job.createdAt
                      )
                        .utc()
                        .format("DD/MM/YYYY")}
                    </h1>
                  )}
                  {textView === "Vaga Encerrada" && (
                    <h1 className="text-indigo-600 font-bold absolute top-6 right-8">
                      {textView}{" "}
                      {moment(
                        jobIdParams
                          ? job?.jobDeadline
                          : jobApply.viewData.job.jobDeadline
                      )
                        .utc()
                        .format("DD/MM/YYYY")}
                    </h1>
                  )}
                </div>
                <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                  <div>
                    <h2 className="text-base font-semibold leading-7 text-indigo-600">
                      Descrição
                    </h2>
                  </div>
                  <p className="">
                    {jobIdParams
                      ? job?.jobDescription
                      : jobApply.viewData.job.jobDescription}
                  </p>
                  <div>
                    <h2 className=" mt-6 text-base font-semibold leading-7 text-indigo-600">
                      Requisitos
                    </h2>
                  </div>
                  <p className="mt-8">
                    <ul className="list-disc pl-4">
                      {jobIdParams
                        ? job?.jobRequirements.map((data, i) => (
                            <li key={i}>{data}</li>
                          ))
                        : jobApply.viewData.job.jobRequirements.map(
                            (data, i) => <li key={i}>{data}</li>
                          )}
                    </ul>
                  </p>
                  <div>
                    <h2 className=" mt-6 text-base font-semibold leading-7 text-indigo-600">
                      Expectativas
                    </h2>
                  </div>
                  <p className="mt-8">
                    <ul className="list-disc pl-4">
                      {jobIdParams
                        ? job?.jobExpectations.map((data, i) => (
                            <li key={i}>{data}</li>
                          ))
                        : jobApply.viewData.job.jobExpectations.map(
                            (data, i) => <li key={i}>{data}</li>
                          )}
                    </ul>
                  </p>
                  <div>
                    <button
                      onClick={applyJob}
                      className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 mt-6 rounded-full"
                    >
                      Quero me candidatar
                    </button>
                  </div>
                </div>
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                  <div className="lg:pr-4">
                    <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                      {/* <p>
                        Categoria: 
                      
                        {jobIdParams
                        ? job?.jobCategory
                        : jobApply.viewData.job.jobCategory}
                      </p> */}

                      <ul role="list" className="mt-8 space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                          <BriefcaseIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Formato de contratação:
                            </strong>{" "}
                            {jobIdParams
                              ? job?.jobType
                              : jobApply.viewData.job.jobType}
                          </span>
                        </li>
                       
                      <li className="flex gap-x-3">
                          <CheckBadgeIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                            Quantidade de etapas no processo seletivo:
                            </strong>{" "}
                            {jobIdParams
                              ? job?.stepJobs
                              : jobApply.viewData.job.stepJobs}
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <DocumentIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Modelo de trabalho:
                            </strong>{" "}
                            {jobIdParams
                              ? job?.workType
                              : jobApply.viewData.job.workType}
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <CurrencyDollarIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              faixa salarial:
                            </strong>{" "}
                            {jobIdParams
                              ? (job?.averageSalary / 10).toLocaleString(
                                  "pt-BR",
                                  {
                                    style: "currency",
                                    currency: "BRL",
                                  }
                                )
                              : (
                                  jobApply.viewData.job.averageSalary / 10
                                ).toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                })}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ViewJob;
