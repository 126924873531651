import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from 'moment'

const generatePDF = async (reportData,total,user) => {
  const doc = new jsPDF({ format: 'a4' })

  const eventsTableColumn = [
    'EVENT NUMBER',
    'EVENT POSTED DATE',
    'EVENT HOSTING DATE',
    'COMPANY NAME ',
    'EVENT NAME',
    'EVENT SIGN-UP COUNT',
    'EVENT SIGN-UP RATE',
    'EVENT MODE',
  ]

  const eventsTableRows = []
  reportData.forEach((data, index) => {
    const eventRowData = [
      ++index,
      moment(data?.createdAt).format('YYYY-MM-DD'),
      moment(data?.date).format('YYYY-MM-DD'),
      data?.company.name,
      data?.eventTitle,
      data?.report.length,
      Math.round(((data?.report.length)*100)/total)+"%",
      data?.deliveryType,
    ]
    eventsTableRows.push(eventRowData)
  })

  doc.text(`Events REPORT`, 70, 15)
  doc.setFontSize(10)
  doc.setTextColor('gray')
  doc.text(
    `REPORT GENERATED ON : ${moment(new Date()).format('MMMM Do YYYY, h:mm:ss a')}`,
    70.5,
    20
  )
  doc.text(
    `REPORT GENERATED BY : ${user}`,
    70.5,
    25
  )

  autoTable(doc, {
    head: [eventsTableColumn],
    body: eventsTableRows,
    headStyles: { fillColor: 'black' },
    startY: 28,
  })


  doc.save(`events-report.pdf`)
}

export default generatePDF
