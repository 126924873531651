import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Home from './pages/Home'
import CompanySignUp from './pages/CompanySignUp'
import PostJob from './pages/PostJob'
import Dashboard from './pages/Dashboard'
import Jobs from './pages/Jobs'
import Companies from './pages/Companies'
import Events from './pages/Events'
import Aboutus from './pages/Aboutus'
import Cursos from './pages/Cursos'
import UserProfile from './pages/UserProfile'
import Saved from './pages/Saved'
import NotFound from './pages/NotFound'
import ProtectedRoute from './components/ProtectedRoute'
import ViewJob from './components/Candidate/ViewJob'
import ViewEvent from './components/Candidate/ViewEvent'
import AppliedJobs from './pages/AppliedJobs'
import { initializeApp } from "firebase/app";
import { getAnalytics,logEvent } from "firebase/analytics";
import Register from './components/Auth/Register'
import Forgot from './components/Auth/Forgot'
import ResendPassword from './components/Auth/ResendPassword'

const firebaseConfig = {

  apiKey: "AIzaSyC2abbdibBskoneiTYCtyP17GAsDRkNBXo",

  authDomain: "newagent-ibubos.firebaseapp.com",

  databaseURL: "https://newagent-ibubos.firebaseio.com",

  projectId: "newagent-ibubos",

  storageBucket: "newagent-ibubos.appspot.com",

  messagingSenderId: "996467469989",

  appId: "1:996467469989:web:6eee52ca15f8a60e08274c",

  measurementId: "G-GGDH6X1J1D"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


const App = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/company/sign-up' element={<CompanySignUp />} />
        <Route path='/user/sign-up' element={<Register/>}/>
        <Route path='/user/forgot' element={<Forgot/>}/>
        <Route path='/user/resend/:token' element={<ResendPassword/>} />
        <Route path='/jobs' element={<Jobs />} />
        <Route path='/companies' element={<Companies />} />
        <Route path='/events' element={<Events />} />
        <Route path='/about' element={<Aboutus />} />
        <Route path='/cursos' element={<Cursos />} />
        <Route path='/candidate/view-job' element={<ViewJob />} />
        <Route path='/candidate/view-event' element={<ViewEvent />} />

        <Route
          path='/company/post-job'
          element={
            <ProtectedRoute>
              <PostJob />
            </ProtectedRoute>
          }
        />
        <Route
          path='/admin/dashboard'
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path='/company/dashboard'
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path='/user-profile'
          element={
            <ProtectedRoute>
              <UserProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path='/saved'
          element={
            <ProtectedRoute>
              <Saved />
            </ProtectedRoute>
          }
        />
        <Route
          path='/appliedjobs'
          element={
            <ProtectedRoute>
              <AppliedJobs />
            </ProtectedRoute>
          }
        />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <ToastContainer
        position='bottom-right'
        autoClose={3000}
        closeOnClick
        draggable
        limit={3}
      />
    </>
  )
}

export default App
