import api from "../../utils/api"
import axios from "axios"
const API_URL = `https://calm-teal-eagle-wrap.cyclic.app/api/v1/company`

//! Get Company
const getCompany = async (companyId) => {
  const response = await api.get(API_URL + `/${companyId}`)
  return response.data
}

//! Get All Company
const getAllCompany = async () => {
  const response = await axios.get(API_URL)
  return response.data
}

//! Update Company
const updateCompany = async (companyData) => {
  const response = await api.patch(API_URL + `/${companyData.id}`, companyData)
  console.log(response, 'aqui')
  return response.data
  
}

const companyService = {
  getCompany,
  getAllCompany,
  updateCompany,
}

export default companyService
