import React, { useState } from 'react';
import { Oval } from 'react-loader-spinner';
import axios from 'axios';
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom';
import {  useNavigate } from 'react-router-dom'

const ResendPassword = () => {
    const { token } = useParams();
    const navigate = useNavigate()
    const [newPassword, setNewPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleRedefinirSenha = async (e) => {
        e.preventDefault(); // Evita o comportamento padrão do formulário

        try {
            setIsLoading(true); // Define isLoading como true durante a requisição

            const response = await axios.post(`https://calm-teal-eagle-wrap.cyclic.app/api/v1/auth/redefinirSenha/${token}`, { newPassword });
            setMessage(response.data); // Define a mensagem de retorno do servidor
            toast.success('Senha redefinida com sucesso');

            setTimeout(() => {
                navigate("/")   
               }, 1000)
            setIsLoading(false); // Define isLoading como false após a requisição
            
        } catch (error) {
            console.error('Erro ao redefinir senha:', error.message);
            setMessage('Erro ao redefinir senha');
            setIsLoading(false); // Define isLoading como false em caso de erro
        }
    };

    return (
        <div className='rounded-lg bg-white shadow font-[Mulish]'>
            <div className='p-6 md:p-12'>
                <h3 className='mb-6 text-xl text-gray-900 font-bold'>
                    Recupere sua senha
                </h3>
                <form onSubmit={handleRedefinirSenha}>
                    <div className='mb-4'>
                        <label htmlFor='email' className='input-label'>
                            Nova senha
                        </label>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className='auth-modal-input'
                            placeholder='Digite sua nova senha'
                        />
                    </div>

                    <button
                        type='submit'
                        className={`w-full rounded-lg bg-[#312ECB] px-5 ${
                            isLoading ? 'py-1' : 'py-3'
                        } text-center text-sm font-bold text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 uppercase flex items-center justify-center`}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <Oval
                                height={30}
                                width={30}
                                color='#fff'
                                secondaryColor='#4fa94d'
                                strokeWidth={6}
                                strokeWidthSecondary={6}
                            />
                        ) : (
                            'Alterar senha'
                        )}
                    </button>
                    {message && <p>{message}</p>}
                </form>
            </div>
        </div>
    );
}

export default ResendPassword;
