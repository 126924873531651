import React from 'react'
import Navbar from '../components/Home/Navbar'

const posts = [
    {
      id: 1,
      title: 'Aula de Python',
      href: '#',
      description:
        'O Município de Ilha Comprida, por intermédio da Casa da Juventude, informa que estão abertas as inscrições para o curso de Python ( Web 3.0, Bitcoin e Blockchain) , que será ministrado aulas às quartas, das 18h30 às 20h, e às sextas, das 18h30 às 20h. Python é uma das principais linguagens de programação de dados. É considerada de alto nível (High Level Language), e conhecida por ser fácil de aprender e de utilizar. Possui diversas aplicações, que vão desde páginas da web, passando por jogos até a dispositivos de Inteligência Artificial (IA).A Casa da Juventude está localizada na Av. São Paulo, s/n, em frente ao Espaço Cultural Plínio Marcos. Podem se inscrever jovens a partir de 14 anos de idade.',
      date: 'Quarta-feira - 18:30 às 20h',
      date2: 'Sexta-feira - 18:30 às 20h',
      datetime: '2020-03-16',
      category: { title: 'Programação', init: '14/02/2024', cidade: 'Ilha Comprida' },
      author: {
        name: 'Casa da Juventude Ilha Comprida',
        role: 'Prefeitura de Ilha Comprida',
        href: 'https://forms.gle/oqnzCFA33mREdfcf9',
        imageUrl:
          'https://www.sdr.sp.gov.br/media/2021/08/casa_juventude.jpg',
      },
    },
    {
        id: 2,
        title: 'Oficina de Desenho, Economia Criativa e Empreendedorismo',
        description:
          'O Município de Ilha Comprida, por intermédio da Casa da Juventude, informa que estão abertas as inscrições para a "Oficina de Desenho, Economia Criativa e Empreendedorismo" - Desenho para empregabilidade ou empreendedorismo artístico, que terá início no dia 14/02, com aulas às quartas e sextas, das 9h30 às 11h e das 13h30 às 15h. A Casa da Juventude está localizada na Av. São Paulo, s/n, em frente ao Espaço Cultural Plínio Marcos. Podem se inscrever jovens a partir de 14 anos de idade.',
        date: 'Quarta-feira - 9h30 às 11h',
        date2: 'Sexta-feira - 13h30 às 15h',
        category: { title: 'Empreendedorismo', init: '14/02/2024', cidade: 'Ilha Comprida' },
        author: {
          name: 'Casa da Juventude Ilha Comprida',
          role: 'Prefeitura de Ilha Comprida',
          href: 'https://forms.gle/oqnzCFA33mREdfcf9',
          imageUrl:
            'https://www.sdr.sp.gov.br/media/2021/08/casa_juventude.jpg',
        },
      },
    // More posts...
  ]

 const Cursos = () => {
    return (
        <>
        <Navbar/>
        <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">Cursos gratuitos em aberto no Vale do Ribeira!</h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              Queremos ajudar nossos jovens e aqueles que buscam aprimorar sua qualificação para o mercado de trabalho! Com a divulgação de cursos gratuitos em nossa região.
            </p>
          </div>
          <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <article key={post.id} className="flex max-w-xl flex-col items-start justify-between">
                <div className="flex items-center gap-x text-xs mb-4">
                  <time className="text-gray-500">
                    {post.date}
                  </time>
                  
                  <time className="text-gray-500">
                    {post.date2}
                  </time>
                  <a className="relative z-10 rounded-full bg-blue-700 px-3 py-1.5 font-medium text-white"
                  >
                    {post.category.title}
                  </a>
                  
                </div>
                <div style={{ marginBottom: 10 }}>
                  <p style={{ color: 'black', fontSize: '1rem', fontWeight: 'bold' }}> Início das aulas {post.category.init}</p>
                </div>
                  <div>
                      <a href="" className="relative z-10 rounded-full bg-red-700 px-3 py-1.5 font-medium text-white">
                          {post.category.cidade}
                      </a>
                  </div>
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a href={post.href}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 text-sm leading-6 text-gray-600">{post.description}</p>
                </div>
                <div className="relative mt-8 flex items-center gap-x-4">
                  <img src={post.author.imageUrl} alt="" className="h-10 w-10 rounded-full bg-gray-50" />
                  <div className="text-sm leading-6">
                    <p className="font-semibold text-gray-900">
                      <a style={{ color: 'blue' }} target="_blank" href={post.author.href} rel="noreferrer">
                        Clique aqui para se cadastrar!
                      </a>
                    </p>
                    <span className="inset-0">{post.author.name}</span>
                        
                    <p className="text-gray-600">{post.author.role}</p>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
      </>
    )
}

export default Cursos
