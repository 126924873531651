import React, { useState } from 'react'
import { Oval } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import {  useNavigate } from 'react-router-dom'


const Forgot = () => {
   const navigate = useNavigate()
   const [email, setEmail] = useState('');
   const [isLoading, setIsLoading] = useState(false);


   const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch('https://calm-teal-eagle-wrap.cyclic.app/api/v1/auth/esqueciSenha', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        toast.success('Email de recuperação de senha enviado com sucesso! Verifique sua caixa de emails ou de spams');
        // Redirecionar para a página ResendPassword
        setTimeout(() => {
         navigate("/")   
        }, 1000)
      } else {
        toast.error('Erro ao enviar email de recuperação de senha');
      }
    } catch (error) {
      console.error('Erro ao enviar solicitação:', error);
      toast.error('Erro ao enviar email de recuperação de senha');
    } finally {
      setIsLoading(false);
    }
  };

    return (
        <div className='rounded-lg bg-white shadow font-[Mulish]'>
          <div className='p-6 md:p-12'>
            <h3 className='mb-6 text-xl text-gray-900 font-bold'>
              Recupere sua senha
            </h3>
            <form onSubmit={handleSubmit}>
              <div className='mb-4'>
                <label htmlFor='email' className='input-label'>
                  Email
                </label>
                <input
                  type='text'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className='auth-modal-input'
                  placeholder='Digite seu email'
                />
              </div>
    
              
              <button
            type='submit'
            className={`w-full rounded-lg bg-[#312ECB] px-5 ${
              isLoading ? 'py-1' : 'py-3'
            } text-center text-sm font-bold text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 uppercase flex items-center justify-center`}
            disabled={isLoading}
          >
            {isLoading ? (
              <Oval
                height={30}
                width={30}
                color='#fff'
                secondaryColor='#4fa94d'
                strokeWidth={6}
                strokeWidthSecondary={6}
              />
            ) : (
              'Recupere sua senha'
            )}
          </button>
            </form>
          </div>
        </div>
      )
}

export default Forgot
