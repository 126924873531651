import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { register as registerUser, reset } from '../../features/auth/authSlice'
import { Oval } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import { openAuth } from '../../features/ui/uiSlice'
import { emailPattern } from '../../constants/pattern'
import BGImage from '../../assets/registerBg.webp'
import Logo from '../../assets/Logo.webp'


const Register = ({ setOpenLogin }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )
  const [imageload, setImageLoad] = useState(true)


  const onSubmit = (data) => {
    data.userRole = 'user'
    dispatch(registerUser(data))
  }

  useEffect(() => {
    if (isError) {
      toast.error(message, { theme: 'dark' })
    }

    if (isSuccess || user) {
      navigate('/')
      dispatch(openAuth(false))
    }

    dispatch(reset())
  }, [user, isError, isSuccess, message, navigate, dispatch])

  return (
    // <div className='rounded-lg bg-white shadow font-[Mulish]'>
    //   <div className='p-6 md:p-12'>
    //     <p className='text-sm text-[#757575] pb-1'>Bem vindo ao Ribeira Talent Hub! 🙏</p>
    //     <h3 className='mb-6 text-xl text-gray-900 font-bold'>
    //       Criar uma conta
    //     </h3>
    <div className='grid grid-cols-8 w-screen h-screen'>
      <div className='col-span-8 lg:col-span-5 w-full font-[Mulish]'>
        <div className='w-10/12 md:w-2/3 mx-auto pt-9 pb-6'>
          <div className='flex items-center gap-x-2 md:mr-12 lg:mr-16 cursor-pointer'>
            <img src={Logo} alt='website-logo' className='w-14' />
            <p className='text-3xl font-bold text-black font-[Domine]'>
                 Bem vindo ao Ribeira Talent! 🙏
            </p>
          </div>

          <div className='mt-4 mb-6 '>
            <p className='font-bold text-xl'>Crie uma conta e encontre as melhores vagas em nossa região!</p>
          </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-4'>
            <label htmlFor='name' className='input-label'>
              Seu nome
            </label>
            <input
              type='text'
              {...register('name', {
                required: 'Nome é obrigatório',
                minLength: {
                  value: 3,
                  message: 'Name must be at least 3 characters',
                },
              })}
              className='auth-modal-input'
              placeholder='Nome completo'
            />
            {errors.name && (
              <p className='text-xs text-red-500 pt-0.5'>
                {errors.name.message}
              </p>
            )}
          </div>

          <div className='mb-4'>
            <label htmlFor='email' className='input-label'>
              Seu email
            </label>
            <input
              type='text'
              {...register('email', {
                required: 'Email é obrigatório',
                pattern: {
                  value: emailPattern,
                  message: 'Please enter a valid email address',
                },
              })}
              className='auth-modal-input'
              placeholder='Seu email'
            />
            {errors.email && (
              <p className='text-xs text-red-500 pt-0.5'>
                {errors.email.message}
              </p>
            )}
          </div>

          <div className='mb-4'>
            <label htmlFor='phone' className='input-label'>
              Seu WhatsApp
            </label>
            <input
              type='text'
              {...register('phone', {
                required: 'WhatsApp é obrigatório',
              })}
              className='auth-modal-input'
              placeholder='Seu WhatsApp'
            />
            {errors.phone && (
              <p className='text-xs text-red-500 pt-0.5'>
                {errors.phone.message}
              </p>
            )}
          </div>

          

          <div className='mb-4'>
            <label htmlFor='password' className='input-label'>
              Senha
            </label>
            <input
              type='password'
              {...register('password', {
                required: 'Senha é obrigatório',
                minLength: {
                  value: 6,
                  message: 'Password must be at least 6 characters',
                },
              })}
              placeholder='Sua Senha'
              className='auth-modal-input'
            />
            {errors.password && (
              <p className='text-xs text-red-500 pt-0.5'>
                {errors.password.message}
              </p>
            )}
          </div>

          <div className='mb-8'>
            <label htmlFor='confirm-password' className='input-label'>
              Confirme sua senha
            </label>
            <input
              type='password'
              {...register('confirm_password', {
                required: 'Confirme sua senha é obrigatório',
                validate: (val) => {
                  if (watch('password') !== val) {
                    return 'Passwords do no match'
                  }
                },
              })}
              placeholder='Confirme sua senha'
              className='auth-modal-input'
            />
            {errors.confirm_password && (
              <p className='text-xs text-red-500 pt-0.5'>
                {errors.confirm_password.message}
              </p>
            )}
          </div>

          <button
            type='submit'
            className={`w-full rounded-lg bg-[#312ECB] px-5 ${
              isLoading ? 'py-1' : 'py-3'
            } text-center text-sm font-bold text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 uppercase flex items-center justify-center`}
          >
            {isLoading ? (
              <Oval
                height={30}
                width={30}
                color='#fff'
                secondaryColor='#4fa94d'
                strokeWidth={6}
                strokeWidthSecondary={6}
              />
            ) : (
              'Criar conta'
            )}
          </button>
        </form>
        </div>
      </div>
      <div className='hidden lg:block col-span-3 w-full relative overflow-hidden'>
        <img
          src={BGImage}
          alt='right-bg'
          loading='lazy'
          onLoad={() => setImageLoad(false)}
          className={`absolute inset-0 w-full h-full object-cover duration-700 ease-in-out ${
            imageload
              ? 'grayscale blur-lg scale-105'
              : 'grayscale-0 blur-0 scale-100'
          }`}
        />
      </div>
    </div>
  )
}

export default Register
