import React from 'react'

const Departament = () => {
    return (
    <div className='w-full h-full bg-[#F9FAFF]'>
      Em desenvolvimento! 
    </div>
  )
}

export default Departament
