import api from '../../utils/api'
import axios from 'axios'

const API_URL = 'https://calm-teal-eagle-wrap.cyclic.app/api/v1/applicant'


//! GET ALL JOB APPLICANTS
const getAllJobApplicants = async ({ companyId, jobId }) => {
  const response = await api.get(`${API_URL}/${companyId}/${jobId}`)
  return response.data
}

//! UPDATE JOB APPLICANT STATUS
const updateApplicantStatus = async ({ appliedJobId, applicantStatus }) => {
  const response = await axios.patch(`${API_URL}/${appliedJobId}`, {
    applicantStatus,
  })
  return response.data
}

const applicantService = {
  getAllJobApplicants,
  updateApplicantStatus,
}

export default applicantService
