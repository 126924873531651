import React from 'react'
import { MdCheck } from 'react-icons/md'
import { useNavigate } from 'react-router'
import Job3 from '../assets/job3.jpg'
import Job2 from '../assets/job2.jpg'
import Job1 from '../assets/job1.jpg'
import Footer from '../components/Home/Footer'
import Nav from '../components/Home/Nav'


const Aboutus = () => {
  const navigate = useNavigate()
  const includedFeatures = [
    'Ribeira Hunting',
    'Suporte 24h, E-mail e Ticket',
    'Agilidade nos processos',
    'Relatórios',
    'Banco de Currículos exclusivo',
    'Etapas da Vaga Personalizadas',
    'Automatização de Documentos',
    'Exportação de dados para BI',
    'Integração Banco de CV´s',
    'Integração com Folha/ERP',
    'Integração com Whatsapp Web',
    'FeedBack´s Automatizados',
    'Envio de docs (Admissão Digital) *15gb',

  ]

  const featuresVip = [
    'Ribeira Hunting',
    'Suporte 24h',
    'Agilidade nos processos',
    'Relatórios',
    'Plataforma de Gestão de R&S',
    'Plataforma de Departamento pessoal',
    'Acesso a plataforma'
  ]

  const featuresPoints = [
    'Ribeira Hunting',
    'Suporte 24h',
    'Agilidade nos processos',
    'Relatórios',
  ]
  

  
  const navigateSignupComapanie = () => {
    navigate('/company/sign-up')
  }
  return (
    <>
    <Nav/>
    <header className='h-screen relative bg-[#4169E1] flex justify-center items-center overflow-hidden'>
    
    <div className='flex flex-col items-center justify-center bg-[#4169E1]  pt-8 pb-16'>
      <h1 className='text-xl md:text-4xl text-center font-[Poppins] text-white font-bold mb-4 md:mb-8 lg:mb-12'>
        Experimente nossas ferramentas de Recrutamento e Seleção!
      </h1>
      <div className="flex flex-col items-center justify-center">
        <button onClick={navigateSignupComapanie} className="mb-4 flex w-100 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Venha conhecer a Ribeira Talent!</button>
      </div>
    </div>
    </header>
    <section className=''>
        <div className="flex flex-col items-center pl-4	 justify-center">
        <h2 className="text-xl md:text-4xl text-center font-[Poppins] text-[#191970] font-bold mb-4 md:mb-8 lg:mb-2 pt-8 items-center">Publicação de vagas imediatas</h2>
        <p className="text-[#4169E1] md:text-sm-1">Publique sua vaga, divulgando para usuários de nossa região cadastrados em nosso banco de talentos. </p>
        <ul>
          <li className="text-[#4169E1] md:text-2xl font-[Poppins]">- Publique quantas vagas quiser!</li>
          <li className="text-[#4169E1] md:text-2xl font-[Poppins]">- Alcance para todo Vale do ribeira</li>
          <li className="text-[#4169E1] md:text-2xl font-[Poppins]">- Cadastro fácil e intuitivo</li>
        </ul>
        <div className="flex flex-col items-center justify-center mt-4">
        <button onClick={navigateSignupComapanie} className="mb-4 flex w-100 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Venha conhecer a Ribeira Talent!</button>
      </div>
        </div>
        <div className="flex flex-col items-end pr-4 justify-center">
             <img style={{ width: 500, height: 500, objectFit: 'contain' }} src={Job2} alt="job1" srcset="" />
        </div>
      </section>
      <section>
        <div className="flex flex-col items-center pr-4 justify-center">
        <h2 className="text-xl md:text-4xl text-center font-[Poppins] text-[#191970] font-bold mb-4 md:mb-6 lg:mb-2 pt-8"> Página de Carreira Integrada </h2>
        <p className="text-center font-[Poppins] text-[#4169E1] md:text-sm-1 mb-4 md:mb-6 lg:mb-6 pt-0">Consiga os melhores currículos da região e monte seu próprio banco de talentos na plataforma</p> 
        <ul>
          <li className="text-[#4169E1] md:text-2xl font-[Poppins]">- Curriculos de profissionais de diversas áreas!</li>
          <li className="text-[#4169E1] md:text-2xl font-[Poppins]">- Página de carreira fácil e intuitiva</li>
        </ul>
        
        <div className="flex flex-col items-center justify-center mt-4">
        <button onClick={navigateSignupComapanie} className="mb-4 flex w-100 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Venha conhecer a Ribeira Talent!</button>
      </div>
        </div>
        <div className="flex flex-col items-start pr-4 justify-center">
             <img style={{ width: 500, height: 500 }} src={Job1} alt="job1" srcset="" />
        </div>
      </section>
      <section>
        <div className="flex flex-col items-center pl-4	 justify-center	 justify-center mb-6">
        <h2 className="text-xl md:text-4xl text-center font-[Poppins] text-[#191970] font-bold mb-4 md:mb-8 lg:mb-2 pt-8 items-center">Ribeira Hunting</h2>
        <p className="text-center font-[Poppins] text-[#4169E1] md:text-sm-1 mb-4 md:mb-6 lg:mb-6 pt-0">Utilizamos nossas melhores tecnologias para atrair, avaliar e entrevistar os candidatos ideais para a sua vaga</p>
        <div className="flex flex-col items-center justify-center mt-4">
        <ul>
          <li className="text-[#4169E1] md:text-2xl font-[Poppins]">- Avaliamos e entrevistamos!</li>
          <li className="text-[#4169E1] md:text-2xl font-[Poppins]">- Conseguimos o candidato ideal para sua empresa</li>
          <li className="text-[#4169E1] md:text-2xl font-[Poppins]">- Sem processos lentos e demorados</li>
          <li className="text-[#4169E1] md:text-2xl font-[Poppins]">- Trasnparência e agilidade</li>
        </ul>
        {/* <button onClick={navigateSignupComapanie} className="mb-4 flex w-100 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4">Venha conhecer a Ribeira Talent!</button> */}
      </div>
        </div>
        <div className="flex flex-col items-center pl-4 justify-center	 justify-center mb-6">
        <img
            src={Job3}
            alt='carres'
            className='object-contain h-100 w-6/12'
          />
        </div>
      </section>
      <section>
        <div className="flex flex-col items-center pr-4	 justify-center">
        <h2 className="text-xl md:text-4xl text-center font-[Poppins] text-[#191970] font-bold mb-4 md:mb-6 lg:mb-2 pt-8 items-center">Plataforma de gestão de candidatos</h2>
        <p className="text-[#4169E1]">Gerencie e organize o banco de talentos de sua empresa, de forma ágil e rápida</p>
        <div className="flex flex-col items-center justify-center mt-4">
        <ul>
          <li className="text-[#4169E1] md:text-2xl font-[Poppins]">- Em breve novas atualizações!</li>
        </ul>
      </div>
        </div>
      </section>
      
      <section className=''>
        <div className="flex flex-col items-center justify-center bg-white mt-6 bg-indigo">
        <button onClick={navigateSignupComapanie} className="mb-4 flex w-100 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Atraia mais talentos. Experimente agora</button>
        </div>
      </section>
        <section>
        <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-[#191970] sm:text-4xl">Planos personalizados!</h2>
          <p className="mt-6 text-lg leading-8 text-[#191970]">
            Entre em contato e comece a contratar de forma ágil e rápido e tenha o melhor da plataforma de gestão de R&S
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-[#191970]">Plano Mensal</h3>
            <p className="mt-6 text-base leading-7 text-gray-600 text-[#191970]">
              Plano único e com o melhor custo beneficío
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">O que está incluido</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <MdCheck className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                {/* <p className="text-base font-semibold text-gray-600">Pay once, own it forever</p> */}
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900 text-[#191970]">99,90</span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600 text-[#191970]" >R$</span>
                </p>
                <a
                  href="mailto:contato@ribeiratalent.com.br?subject=Plano Mensal"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Experimente agora
                </a>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                Faturas e recibos disponíveis para fácil reembolso da empresa
                </p>
              </div>
            </div>
          </div>
        </div>

    

       
      </div>
    </div>
        </section>

      
      <Footer/>
    </>
  )
}

export default Aboutus
