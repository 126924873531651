import Category1 from '../assets/blue.jpeg'
import Category2 from '../assets/blue.jpeg'
import Category3 from '../assets/blue.jpeg'
import Category4 from '../assets/blue.jpeg'
import Category5 from '../assets/blue.jpeg'
import Category6 from '../assets/blue.jpeg'



export const categories = [
  {
    name: 'Administrativo',
    paramName: 'Administrativo',
    image: Category1,
  },
  {
    name: 'Financeiro',
    paramName: 'Financeiro',
    image: Category2,
  },
  {
    name: 'Saúde',
    paramName: 'Saúde',
    image: Category3,
  },
  {
    name: 'Tecnologia',
    paramName: 'Tecnologia',
    image: Category4,
  },
  {
    name: 'Comercial',
    paramName: 'Accounting',
    image: Category5,
  },
  {
    name: 'Outros',
    paramName: 'Outros',
    image: Category6,
  },
]
